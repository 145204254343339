// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@/core/Icon.atom';
import { Overlay, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/core/Checkbox.atom';
import { useFlux } from '@/core/hooks/useFlux.hook';
import ValueWithUnits from '@/trend/ValueWithUnits.atom';
import { humanizeInterval } from '@/datetime/dateTime.utilities';
import { sqDurationStore } from '@/core/core.stores';
import { AUTO_UPDATE } from '@/trendData/trendData.constants';
import { autoUpdate as autoUpdateDurationActions } from '@/trendData/duration.actions';
import { setChartWidth } from '@/trendData/trend.actions';

export const AutoUpdate: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const humanizeAutoInterval = () => {
    return t('AUTO_UPDATE.AUTO_INTERVAL', {
      autoInterval: humanizeInterval(autoUpdate.autoInterval),
    });
  };

  const [showAutoUpdateModal, setShowAutoUpdateModal] = useState(false);
  const [target, setTarget] = useState(null);
  const [invalidManualInterval, setInvalidManualInterval] = useState(false);
  const timeoutInstance = useRef(null);
  const { autoUpdate } = useFlux(sqDurationStore);

  // intentionally added into setState in order to not call humanizeInterval on each rerender
  const [autoInterval, setAutoInterval] = useState(humanizeAutoInterval());

  useEffect(() => {
    if (!sqDurationStore.displayPixels) {
      setChartWidth();
    }
  }, []);

  useEffect(() => {
    setAutoInterval(humanizeAutoInterval());
  }, [autoUpdate.autoInterval, showAutoUpdateModal]);

  useEffect(() => {
    // handler used to close the picker on outside click
    const handleClick = (e) => {
      if (e.target.getAttribute('data-itemid') === 'autoUpdatePopover') {
        return;
      }
      closeAutoUpdateModal();
    };

    // add when mounted
    document.querySelector('#mainView')?.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const closeAutoUpdateModal = () => setShowAutoUpdateModal(false);
  const openAutoUpdateModal = (event) => {
    setShowAutoUpdateModal(true);
    event.stopPropagation();
    setTarget(event.currentTarget);
  };

  const setMode = (event) => {
    const newValue = event.target.value;
    autoUpdate.mode !== newValue && autoUpdateDurationActions.setMode(newValue);
  };

  const isInvalidManualInterval = (interval) => {
    return (
      !_.isNumber(interval.value) ||
      interval.value <= 0 ||
      (interval.units === 's' && interval.value < AUTO_UPDATE.MIN_INTERVAL / 1000)
    );
  };

  const onManualIntervalChange = (interval) => {
    const isInvalid = isInvalidManualInterval(interval);
    autoUpdate.mode !== AUTO_UPDATE.MODES.MANUAL && autoUpdateDurationActions.setMode(AUTO_UPDATE.MODES.MANUAL);
    timeoutInstance.current && clearTimeout(timeoutInstance.current);
    if (isInvalid) {
      // Delay setting error so user has opportunity to clear field and type new number without error message
      timeoutInstance.current = setTimeout(() => {
        setInvalidManualInterval(isInvalid);
      }, 1000);
      return;
    }
    setInvalidManualInterval(false);
    autoUpdateDurationActions.setManualInterval(interval);
  };

  const toggleAutoUpdateModal = (event) => (showAutoUpdateModal ? closeAutoUpdateModal() : openAutoUpdateModal(event));
  const getManualCustomDescription = () => (
    <span className="manualIntervalContainer pl10">
      <ValueWithUnits
        extraClassNames="mt0 mb5 ml0"
        onChange={onManualIntervalChange}
        min={1}
        defaultValue={autoUpdate.manualInterval}
        required={true}
        insideModal={true}
      />
    </span>
  );

  const isOffMode = autoUpdate.mode === AUTO_UPDATE.MODES.OFF;

  return (
    <div className="cursorPointer mt2 ml5">
      <div onClick={toggleAutoUpdateModal} data-testid="autoUpdateButton">
        <Icon
          testId="autoUpdateButtonIcon"
          icon={classNames('flexCenter', {
            'fc-refresh-off': isOffMode,
            'fc-refresh-on': !isOffMode,
          })}
          tooltip="AUTO_UPDATE.HEADER"
          tooltipPlacement="bottom"
          tooltipDelay={1500}
        />
      </div>
      <Overlay target={target} show={showAutoUpdateModal} placement="top" transition={false}>
        <Popover
          id="autoUpdatePopover"
          data-testid="autoUpdatePopover"
          className="popover autoUpdatePopup"
          arrowProps={{ ref: null, style: { bottom: '-20px', left: '6px' } }}>
          <Popover.Title className="popover-title">
            <span>{t('AUTO_UPDATE.HEADER')}</span>
            <Icon
              icon="fa-question-circle"
              extraClassNames="text-interactive pl5"
              large={true}
              tooltip="AUTO_UPDATE.EXPLANATION"
            />
          </Popover.Title>
          <Popover.Content>
            <div className="picker-close" onClick={closeAutoUpdateModal}>
              <span className="fa fa-close cursorPointer" />
            </div>
            <div className="flexRowContainer autoUpdateOptions">
              <Checkbox
                id="autoUpdate"
                label={t('AUTO_UPDATE.AUTO')}
                extraLabel={
                  <span className="pl5" data-testid="autoInterval">
                    {autoInterval}
                  </span>
                }
                classes="flexRowContainer mt5 mb10 ml10 width-auto flexAlignStart"
                descriptionClasses="width-auto ml10 mr10 mt5 small"
                type="radio"
                value={AUTO_UPDATE.MODES.AUTO}
                description={t('AUTO_UPDATE.AUTO_DESCRIPTION')}
                isChecked={autoUpdate.mode === AUTO_UPDATE.MODES.AUTO}
                onChange={setMode}
              />
              <span>
                <Checkbox
                  id="updateManual"
                  label={t('AUTO_UPDATE.MANUAL')}
                  extraLabel={getManualCustomDescription()}
                  classes="flexRowContainer mt5 mb0 ml10 flexAlignStart updateManualContainer"
                  descriptionClasses="width-auto ml10 mr10 mt5 small"
                  type="radio"
                  value={AUTO_UPDATE.MODES.MANUAL}
                  description={t('AUTO_UPDATE.MANUAL_DESCRIPTION')}
                  isChecked={autoUpdate.mode === AUTO_UPDATE.MODES.MANUAL}
                  onChange={setMode}
                />
                {invalidManualInterval && (
                  <div className="ml20 small sq-fairly-dark-gray text-italic errorMsg">
                    {t('AUTO_UPDATE.INVALID_MANUAL_INTERVAL')}
                  </div>
                )}
              </span>
              <Checkbox
                id="updateOff"
                label={t('AUTO_UPDATE.OFF')}
                classes="flexRowContainer mt15 mb10 ml10 width-auto flexAlignStart"
                descriptionClasses="width-auto ml10 mr10 mt5 small"
                type="radio"
                value={AUTO_UPDATE.MODES.OFF}
                description={t('AUTO_UPDATE.OFF_DESCRIPTION')}
                isChecked={isOffMode}
                onChange={setMode}
              />
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};
